import * as React from "react";

interface Props {
    label?: string | JSX.Element;
    textSize?: "sm";
}
const Input: React.ForwardRefExoticComponent<
    Props &
        React.InputHTMLAttributes<HTMLInputElement> &
        React.RefAttributes<HTMLInputElement>
> = React.forwardRef<
    HTMLInputElement,
    Props & React.InputHTMLAttributes<HTMLInputElement>
>((props, ref) => {
    const { label, className, textSize, ...rest } = props;

    let textSizeClass = "";

    switch (textSize) {
        case "sm":
            textSizeClass = "text-sm";
            break;
    }

    return (
        <div className={`${className ?? ""} w-full`}>
            <label>
                {label ? <div className="mb-1">{label}</div> : null}
                <input
                    ref={ref}
                    className={`form-input rounded-sm md:rounded w-full disabled:bg-gray-100 border-gray-300 hover:border-brand-600 active:border-brand-400 focus:ring-2 focus:ring-brand-300 ${textSizeClass}`}
                    {...rest}
                />
            </label>
        </div>
    );
});

Input.displayName = "Input";
export { Input };
