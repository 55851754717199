import { SupportEmailLink } from "components/app/support-email-link";
import * as React from "react";

interface Props {
    component: string;
    children: React.ReactNode;
}

export const ErrorMessage: React.FC<Props> = props => {
    const { component, children } = props;
    return (
        <div className="flex space-x-2 lg:space-x-4">
            <div className="w-16 lg:w-32">
                <svg
                    className="text-yellow-500"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                </svg>
            </div>
            <div>
                <h3 className="font-semibold text-lg">
                    Sorry to interrupt what you were doing!
                </h3>
                <p>
                    Please try reloading your browser and try again. If that doesn&apos;t
                    fix the problem, please email{" "}
                    <SupportEmailLink subject={`Error in ${component}`} />.
                </p>
                <details style={{ whiteSpace: "pre-wrap" }}>{children}</details>
            </div>
        </div>
    );
};
