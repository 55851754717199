import { ErrorMessage } from "components/ui/error-message";
import { Spinner } from "components/ui/spinner";
import React from "react";
import { UseQueryResult } from "react-query";

interface QueryRendererProps<T> {
    name: string;
    children: (data: T) => React.ReactNode;
    query: UseQueryResult<T>;
    loadingComponent?: React.ReactElement | null;
}

export const QueryRenderer = <T,>(props: QueryRendererProps<T>): JSX.Element => {
    const { name, children, query } = props;

    const loadingComponent =
        typeof props.loadingComponent === "undefined" ? (
            <Spinner />
        ) : (
            props.loadingComponent
        );

    if (query.isLoading) {
        return loadingComponent;
    }
    if (query.isError) {
        return (
            <ErrorMessage component={name}>
                <pre>{JSON.stringify(query.error, null, 2)}</pre>
            </ErrorMessage>
        );
    }
    if (!query.data) {
        return loadingComponent;
    }
    return <>{children(query.data)}</>;
};
