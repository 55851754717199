import { defaultLinkStyle } from "components/ui/link";
import React from "react";
import { useSupportEmailAddress } from "store/selectors/branding";

interface SupportEmailLinkProps {
    label?: string;
    subject?: string;
}

export const SupportEmailLink: React.FC<SupportEmailLinkProps> = props => {
    const { label, subject } = props;
    const supportEmail = useSupportEmailAddress();

    const mailTo = `mailto:${supportEmail}`;
    const href = subject ? `${mailTo}?subject=${subject}` : mailTo;

    return (
        <a className={defaultLinkStyle} href={href}>
            {label ?? supportEmail}
        </a>
    );
};
